/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
// Components
import SEO from 'components/SEO'
import { Layout, Hero2, Article } from 'components/shared'
import { Button, WhatsappButton } from 'components/elements'
import ParseContent from 'components/shared/ParseContent'

import TriangleImg from 'img/triangle.png'
import TriangleImg2 from 'img/triangle_white.png'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        services {
          title
          usp {
            title
            description
          }
          button {
            title
            url
          }
        }

        meta {
          title
          description
          button {
            title
            url
          }
        }
      }

      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoastMeta, acf },
  },
}) => {
  return (
    <Layout headerActive="Diensten">
      {yoastMeta && (
        <SEO
          yoast={{ meta: yoastMeta }}
          pathname={path}
          image={acf.banner.image.localFile.childImageSharp.fluid.src}
          article
        />
      )}

      <Hero2 backgroundImg={acf.banner.image} title={acf.banner.title} />
      <WhatsappButton />
      <div className="d-flex flex-wrap">
        {acf.services.map(({ title, usp, button }, index) => (
          <ServiceSection
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={title}
            button={button}
            usp={usp}
            left={index % 2 === 0}
          />
        ))}
      </div>
      <Article
        title={acf.meta.title}
        description={acf.meta.description}
        button={acf.meta.button}
      />
    </Layout>
  )
}

export default PageTemplate

const ServiceSectionContainer = styled.div`
  width: 50%;
  padding: 45px 0 90px 0;
  transition: all 300ms ease;
  z-index: 0;
  background: ${(props) => (props.left ? '#F1F1F1' : '#CAB996')};
  @media screen and (max-width: 1060px) {
    padding: 30px 0 60px 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const ServiceSectionWrapper = styled.div`
  margin-left: ${(props) => (props.left ? 'auto' : '100px')};
  margin-right: ${(props) => (props.left ? '100px' : 'auto')};
  width: 420px;
  transition: all 300ms ease;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1060px) {
    margin-left: ${(props) => (props.left ? 'auto' : '60px')};
    margin-right: ${(props) => (props.left ? '60px' : 'auto')};
    width: 300px;
  }
  @media screen and (max-width: 768px) {
    width: 300px;
    margin: 0 auto;
  }
`
const ServiceSectionTitle = styled.h5`
  color: black;
  text-align: center;
  font-size: 25px;
  line-height: 30px;
  padding: 0 8px 18px 8px;
  border-bottom: ${(props) =>
    props.left ? '3px solid #cab996' : '3px solid white'};
  margin-bottom: 60px;
  width: fit-content;
  margin-left: auto;
  transition: all 300ms ease;
  margin-right: auto;
  @media screen and (max-width: 1060px) {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 40px;
    padding: 0 8px 12px 6px;
  }
`
const ServiceSection = ({ title, left, button, usp }) => {
  return (
    <ServiceSectionContainer left={left}>
      <ServiceSectionWrapper left={left}>
        <ServiceSectionTitle left={left}>{title}</ServiceSectionTitle>
        {usp.map((item, index) => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <ServiceItem key={`service${index}`} {...item} left={left} />
        })}
        <div className="d-flex justify-content-center">
          <Button label={button.title} shadowColor="#D8D8D8" to={button.url} />
        </div>
      </ServiceSectionWrapper>
    </ServiceSectionContainer>
  )
}
const ServiceItemContainer = styled.div`
  margin-bottom: 56px;
  @media screen and (max-width: 1060px) {
    margin-bottom: 44px;
  }
`
const ServiceItemHeading = styled.div`
  display: flex;

  img {
    margin-right: 12px;
    width: 26px;
    height: 30px;
    object-fit: contain;
    object-position: center;
  }
  h6 {
    font-size: 25px;
    line-height: 30px;
  }
  transition: all 300ms ease;
  @media screen and (max-width: 1060px) {
    img {
      margin-right: 10px;
      width: 20px;
      height: 24px;
      object-fit: contain;
      object-position: center;
    }
    h6 {
      font-size: 18px;
      line-height: 24px;
    }
  }
`
const ServiceItemDescription = styled(ParseContent)`
  text-align: left;
  color: black;
  font-size: 16px;
  line-height: 30px;
  margin-left: 36px;
  transition: all 300ms ease;
  @media screen and (max-width: 1060px) {
    font-size: 13px;
    line-height: 20px;
    margin-left: 30px;
  }
`
const ServiceItem = ({ title, description, left }) => {
  return (
    <ServiceItemContainer style={{ minHeight: 220 }}>
      <ServiceItemHeading>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={left ? TriangleImg : TriangleImg2} />
        <h6>{title}</h6>
      </ServiceItemHeading>
      <ServiceItemDescription content={description} />
    </ServiceItemContainer>
  )
}
